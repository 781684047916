.supportContainer {
    padding: 40px;
}

.supportTable {
    width: 100%;
}

.supportTable th:first-of-type {
    width: 100px;
}

.supportTable th:nth-of-type(2) {
    width: 100px;
}

.supportTable th:nth-of-type(3) {
    width: 100px;
}

.supportTable th:nth-of-type(4) {
    width: 100px;
}

.supportTable th:nth-of-type(5) {
    width: 600px;
}

.supportTable th:nth-of-type(6) {
    width: 100px;
}

.supportTable th:nth-of-type(7) {
    width: 200px;
}