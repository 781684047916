.feedbackContainer {
    padding: 40px;
}

.feedbackTable {
    width: 100%;
}

.feedbackTable th:first-of-type {
    width: 100px;
}

.feedbackTable th:nth-of-type(2) {
    width: 100px;
}

.feedbackTable th:nth-of-type(3) {
    width: 600px;
}

.feedbackTable th:nth-of-type(4) {
    width: 100px;
}

.feedbackTable th:nth-of-type(5) {
    width: 100px;
}