.loginContainer {
  margin: 20px;
}

.loginInput {
  display: block;
  margin: 20px;
}

.loginButton {
  display: block;
  margin: 20px;
}
